import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image'
import React from 'react'
import Layout from '~/components/layout'
import { Container, HeroSection, HeroSectionImage, Content } from './styles'
import { H1, H2, H3, H4, H5, Paragraph } from '~/styled/components/typography'
import { Box, Grid, Text } from 'theme-ui'
import { camelCase } from 'lodash'
import { CustomRugStudioForm } from '~/utils/customRugStudio'
import { Link } from 'gatsby'

export interface IAboutBenData {
  heroBackground: ImageDataLike
  contentTitle: string
  signatureImage: ImageDataLike
  picture: ImageDataLike
  content: string
}

export interface AboutBenProps {
  data: IAboutBenData
}

const AboutBen: React.FC<AboutBenProps> = ({ data }) => {
  const { contentTitle, content } = data
  const heroBackground = getImage(data.heroBackground)
  const signatureImage = getImage(data.signatureImage)
  const picture = getImage(data.picture)

  return (
    <Layout
      headerVariant="default"
      title={contentTitle}
      description={content}
      topBanner={false}
      stickyMenu={true}
      headerSxProps={{
        '.desktop': {
          height: 136,
        },
        '.mobile-content': {
          height: 90,
        },
      }}
      sx={{
        pt: [90, null, null, 136],
      }}
    >
      <HeroSection>
        {heroBackground && (
          <HeroSectionImage>
            <GatsbyImage image={heroBackground} alt={contentTitle} />
          </HeroSectionImage>
        )}
      </HeroSection>

      <Container>
        <Content>
          <H2 style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: contentTitle }} />
          {signatureImage && (
            <GatsbyImage className="signature" image={signatureImage} alt="signature" />
          )}
          {picture && <GatsbyImage className="picture" image={picture} alt="signature" />}
          <Paragraph dangerouslySetInnerHTML={{ __html: content }} />
        </Content>
      </Container>
    </Layout>
  )
}

export default AboutBen
