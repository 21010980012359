import styled, { css } from 'styled-components'
import { Paragraph } from '~/styled/components/typography'

export const HeroSection = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1920 / 480;
  min-height: 480px;

  ${({ theme }) => '@media ' + theme.mediaQueries.maxMd} {
    min-height: 170px;
    aspect-ratio: 1920 / 170;
  }
`

export const HeroSectionImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

export const Container = styled.div`
  max-width: 1920px;
  margin: 0 auto;

  box-sizing: border-box;

  color: #666666;

  a {
    color: #666666;
  }

  h1,
  h2,
  h3 {
    margin-bottom: 30px;
    color: #666;
  }

  p {
    line-height: 35px;
    margin-top: 0;
    margin-bottom: 30px;
  }
`

export const Content = styled.div`
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
  padding: 64px 20px;

  ${({ theme }) => '@media ' + theme.mediaQueries.maxMd} {
    padding: 34px 20px;
  }

  display: flex;
  flex-direction: column;
  gap: 30px;

  .signature {
    width: 108px;
    height: 97px;
    object-fit: contain;
    margin: 0 auto 50px;
  }

  .picture {
    margin: 0 auto 20px;
    width: 386px;
    height: 506px;
  }
`
